import { Lock, Stripe } from "@hoken/core";

import { StripeBadgeVariants } from "./stripe-badges.interfaces";
import { StripeLockSlot, StripeLockText } from "./stripe-badges.styles";

import { Autolayout } from "@hoken/design-system";

export const StripeBadge = ({ color, linked }: StripeBadgeVariants) => {
  return (
    <Autolayout direction='row' gap='small' align='center'>
      {linked ? (
        <a href='https://stripe.com/' target='_blank' rel='noreferrer'>
          <Stripe />
        </a>
      ) : (
        <Stripe />
      )}
      <StripeLockSlot>
        <Lock />
        <StripeLockText color={color}>
          Guaranteed <span>safe & secure</span>
        </StripeLockText>
      </StripeLockSlot>
    </Autolayout>
  );
};
