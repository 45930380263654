import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

/* istanbul ignore next */
const LockSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}

    <path
      d='M2.344 6V4.594A4.22 4.22 0 0 1 6.563.375c2.314 0 4.218 1.904 4.218 4.219V6h.469c1.025 0 1.875.85 1.875 1.875V13.5a1.87 1.87 0 0 1-1.875 1.875H1.875A1.851 1.851 0 0 1 0 13.5V7.875A1.87 1.87 0 0 1 1.875 6h.469Zm1.875 0h4.687V4.594A2.35 2.35 0 0 0 6.562 2.25 2.332 2.332 0 0 0 4.22 4.594V6Z'
      fill='#C0BFCD'
    />
  </svg>
);

export const Lock = styled(LockSVG, {
  height: "16px",
  width: "14px",
  margin: "$hoken-core-space-none $hoken-core-space-small",
});
