import { styled } from "@hoken/core/styles/stitches.config";

export const StripeBadgeSlot = styled("div", {
  display: "flex",
  placeItems: "center",
  alignItems: "end",

  variants: {
    column: {
      reverse: {
        flexDirection: "column-reverse",
        div: {
          marginTop: "$2",
        },
      },
    },
    justify: {
      between: {
        justifyContent: "space-between",
      },
    },
    color: {
      grey70: {
        color: "$grey70",
        "svg path": { fill: "$grey70" },
      },
    },
  },
});

export const StripeLockSlot = styled("div", {
  display: "flex",
});

export const StripeLockText = styled("p", {
  fontFamily: "$franklin",
  fontWeight: "$regular",
  fontSize: "$hoken-core-font-11",
  lineHeight: "$hoken-core-line-height-17",
  minWidth: "none",
  whiteSpace: "nowrap",
  textAlign: "right",

  span: {
    fontWeight: "$bold",
    color: "$white",
  },

  variants: {
    color: {
      grey70: {
        span: {
          fontWeight: "$bold",
          color: "$grey70",
        },
      },
    },
  },
});
