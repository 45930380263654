import type React from "react";
import { HTMLAttributes } from "react";

import { autolayout, variants } from "./autolayouts.vanilla.css";

import { classMerge } from "@hoken/design-system";

type Variants = {
  [key in keyof typeof variants]?: keyof typeof variants[key];
};

interface AutolayoutProps extends HTMLAttributes<HTMLParagraphElement>, Variants {
  as?: keyof HTMLElementTagNameMap;
  children: React.ReactNode;
}

export const Autolayout = ({
  as = "div",
  align,
  children,
  direction,
  gap,
  grid,
  justify,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  maxWidth,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  width,
  ...props
}: AutolayoutProps) => {
  const autoLayoutClassName = autolayout({
    align,
    direction,
    gap,
    grid,
    justify,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    maxWidth,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    width,
  });
  const customClassName = props?.className;
  const compoundClassName = classMerge([autoLayoutClassName, customClassName]);

  const Component = as as any;

  return (
    <Component {...props} className={compoundClassName}>
      {children}
    </Component>
  );
};
